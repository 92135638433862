<template>
  <div>
    <el-row justify="space-between" align="middle">
      <el-col :span="6"><h3 class="mb-0">Doctor List</h3></el-col>
      <el-col :sm="24" :md="12" :lg="12" v-if="authUser.user_type == 'executive' || authUser.user_type == 'crm-manager'" class="text-right">
        <el-select @change="getDcotors()" class="m-2" v-model="center_id" placeholder="Select Center"
                   size="large" clearable>
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <br>
    <el-table :data="filteredList" v-loading="loading" border style="width: 100%">
      <el-table-column label="Name" width="400">
        <template #default="scope">
          <UserCard :name="scope.row.username" :phone_number="scope.row.phone_number"
                    :image="scope.row.profile_image"></UserCard>
          <el-tag class="ml-2" type="success" v-if="scope.row.sub_type=='doctor'">{{ scope.row.sub_type }}</el-tag>
          <el-tag class="ml-2" type="warning" v-else>{{ scope.row.sub_type }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="speciality" label="Speciality"/>
      <el-table-column prop="designation" label="Designation"/>
      <el-table-column prop="created_at" label="Create Date"/>
      <el-table-column prop="actions" label="Action">
        <template #default="scope">

          <router-link class="btn btn-primary" :to="`/doctor/${scope.row.id}?name=${scope.row.username}`" v-if="checkUsrPermissions('User profile')">Details
          </router-link>

          <!-- <router-link class="btn btn-danger" :to="`/doctor/${scope.row.id}?name=${scope.row.username}&appointment=appointment`">Appointment</router-link> -->

        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'DoctorList',
  components: {
    UserCard,
  },
  mixins: [CenterMixins],
  data() {
    return {
      tableData: [],
      search: '',
      loading: false,
    };
  },
  created() {
    this.getDcotors();
  },
  computed: {
    filteredList() {
      return this.tableData.filter(
        (data) => !this.search
              || data.name.toLowerCase()
                .includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    getDcotors() {
      this.loading = true;
      axios.get(`/api/v2/doctor/center-wise/list${this.center_id ? `?institute_id=${this.center_id}` : ''}`)
        .then((response) => {
          this.tableData = response.data.data;
          this.loading = false;
        });
    },
  },
  title: 'Doctors',
};
</script>

<style scoped>

</style>
